.ChatWindow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  border: 2px solid black;
  padding: 15px 25px;
  margin: 20px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;

  /* margin-bottom: 100px; */
}
.ChatWindow:hover {
  background: linear-gradient(to right, #1ca7ec, #7bd5f5);
  border: solid #7bd5f5;
  box-shadow: 0px 4px 10px rgba(123, 213, 245, 0.913);
  color: white;
  /* transition: all 0.3s ease; */
  /* transition: background-color 0.5s ease; */
}

.ChatWindow:hover h4 {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.851));
}

.chatname .chatemail {
  display: block;
}

.userdetails {
  display: flex;
  flex-direction: row;
}

.user {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.userID {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .ChatWindow {
    display: flex;
    /* flex-direction: row; */
  }
  .user {
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .ChatWindow {
    width: 100%;
    margin: 10px 0 0 0;
  }
  .user {
    font-size: 10px;
  }
}
