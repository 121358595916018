.chatTable {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid lightgray; */
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  margin-left: 30px;
  height: 500px;
  max-height: 500px;
  max-width: 50%;
}

.chatTable__data {
  overflow: scroll;
  flex: 1;
  margin-bottom: 30px;
}
.tableData td {
  text-transform: capitalize;
}

.tableData td {
  max-width: 100%;
}
/* .data {
	display: flex;
	flex-direction: row;
} */
/* 
.headers {
  font-weight: 800;
} */
/* .tableHeading {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  text-align: center;
} */
/* 
.tableRow:nth-child(2n + 1) {
  background-color: lightgray;
} */

/* .tableRow {
  height: 50px;
} */
.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

/* .heading > h3 {
  display: flex;
  width: 33%;
  justify-content: center;
  text-align: center;
} */

/* .value {
  margin-right: 20px;
} */

/* th,
td {
  padding: 10px 3px;
} */

/* th,
td {
  border: 1px solid black;
  width: 30%;
} */

.tableData {
  width: 100%;
}

tbody {
  width: 100%;
}

.chattableheading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 500px;
}
thead {
  border-bottom: 1px solid black;
}
table {
  border-collapse: collapse;
  overflow: hidden;
}

td {
  text-align: center !important;
}

/* tr {
	  border-bottom: 1pt solid black;
	} */
/* thead {
  margin-bottom: 10px;
  padding-bottom: 10px;
} */
.theadrow {
  /* margin-bottom: 10px; */
  color: #1e1e1e;
  font-size: 18px;
  padding: 5px 10px;
  border-bottom: 1px solid #4e4e4e;
  text-align: center;
}
.theadrow td {
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
}
.tableData td {
  padding: 15px 20px;
  /* text-align: center; */
}
table tr:nth-child(even) {
  background-color: rgb(248, 236, 220, 0.7);
}
.capitalise {
  text-transform: capitalize;
}

.user-data-table::-webkit-scrollbar {
  /* display: none; */
  width: 0;
}

.firstColumn,
.secondColumn,
.thirdColumn {
  width: 33% !important;
}

@media screen and (max-width: 1024px) {
  .chatTable {
    max-width: 80%;
    margin-left: 0;
    margin-top: 20px;
    margin: 20px auto;
  }
}

@media screen and (max-width: 768px) {
  .chatTable {
    max-width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .table {
    width: auto;
    overflow-x: scroll;
    table-layout: auto;
    width: 100%;
  }
  .tableData h3 {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 12px;
  }
  th,
  td {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 10px;
    table-layout: auto;
    /* width: 100%; */
  }
  .chattableheading {
    width: 350px;
    font-size: 20px;
  }

  .heading h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .chatTable {
    max-height: 400px;
  }
  th,
  td {
    padding: 5px 2px;
  }
  .heading h3 {
    font-size: 10px;
  }
  .chattableheading {
    width: 300px;
    font-size: 16px;
  }
  .firstColumn,
  .secondColumn,
  .thirdColumn {
    width: 30% !important;
  }
}
