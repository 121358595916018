.login {
  /* position: absolute; */

  max-width: 300px;

  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.login p {
  text-align: center;
  font-size: 30px;
  color: #3ea7ec;
  margin-bottom: 30px;
  font-weight: 800;
}

.login form {
  display: flex;
  height: 280px;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid lightgray;
  padding: 30px;
  border-radius: 20px;
}

.login input {
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.login__button {
  margin-top: 20px;
  background-color: #3ea7ec;
  color: white;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #3ea7ec;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  font-size: 18px;
}

.login__button:hover {
  background-color: #fff;
  color: #3ea7ec;
  border: 2px solid #3ea7ec;
  box-sizing: border-box;
}

.error {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  padding: 10px 5px;
  border-radius: 20px;
  border: 2px solid #571515;
  font-size: 14px;
  animation: error 1s infinite alternate;
}

@keyframes error {
  from {
    background-color: #571515;
  }
  to {
    background-color: #ba2a2a;
    /* color: #ed1f30; */
  }
}
