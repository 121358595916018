.change-email-params {
  width: 60%;
  margin: 100px auto;
  padding: 2rem;
  text-align: center;
}
.change-email-params input {
  padding: 20px 10px;
  margin: 10px 0px;
  background: #fff;
  outline: none;
}
.change-email-params button {
  width: 50%;
  text-align: center;
  padding: 20px 10px;
  border: 1px solid #1ca7ec;
  border-radius: 5px;
  outline: none;
  background: #1ca7ec;
  color: #fff;
  cursor: pointer;
}
button:active {
  outline: none;
}
