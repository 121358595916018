.sessionId {
  width: 80%;
  margin: auto;
}

.sessionId h2,
h5 {
  text-align: center;
}

.sessionWindow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;

  padding: 15px 25px;
  margin: 20px;
  border-radius: 10px;
  color: white;
  /* animation: bG 1s infinite alternate-reverse; */
  background-color: rgba(62, 166, 236, 0.8);
}

.sessionWindow__heading {
  display: flex;
  justify-content: center;
  font-size: 18px;
}
.sessionId {
  margin-bottom: 100px;
}
@keyframes bG {
  0% {
    background-color: rgba(62, 166, 236, 0.8);
    border: 3px solid rgba(62, 167, 236, 0.8);
  }
  100% {
    background-color: rgba(62, 167, 236, 1);
    border: 3px solid rgba(62, 167, 236, 1);
  }
}

@media screen and (max-width: 768px) {
  .sessionId {
    width: 85%;
  }
  .sessionWindow__heading {
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .sessionId {
    width: 95%;
  }
  .sessionWindow {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .sessionWindow__heading {
    font-size: 0.9rem;
  }
}
