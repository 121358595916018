.chat {
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 30px;
  max-width: 50%;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  overflow: scroll;
  height: 500px;
  max-height: 500px;
  flex: 0.9;
  margin-bottom: 50px;
}

.left {
  position: relative;
  border-radius: 10px;
  display: block;
  max-width: 400px;
  margin-right: auto;
  margin-bottom: 20px;
  background: linear-gradient(to right, #1ca7ec, #7bd5f5);
  color: white;
  font-size: 14px;
  text-align: left;
  padding: 10px 15px;
  /* box-shadow: 1px 1px 5px 1px lightgray; */
}

.dropShadow {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.851));
}

.left:hover {
  box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.left::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: -40px;
  border-radius: 50%;
  background: url(../src/images/Avatar-03.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #ddd;
  /* box-shadow: 0px 0px 5px 1px rgba(28, 167, 236, 0.906); */
}

.right {
  position: relative;
  display: block;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px 15px;
  width: auto;
  max-width: 400px;
  margin-left: auto;
  margin-bottom: 20px;
  color: white;
  background: linear-gradient(90deg, #1ca7ec 0, #7ad5f5);
  box-shadow: 1px 1px 5px 1px lightgray;
  -webkit-appearance: initial;
}
.showTime {
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  margin-bottom: 20px;
  margin-left: auto;
  color: lightslategray;
  font-size: small;
}

.right:hover {
  box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.right::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-right: -40px;
  border-radius: 50%;
  background: url(../src/images/CustomerIcon\ 1.svg);
  background-position: center center;
  background-size: contain;
  border: 1px solid #ddd;
  /* box-shadow: 0px 1px 10px rgba(123, 213, 245, 0.89); */
}

.chatBox {
  display: flex;
  width: auto;
  padding: 20px;
}

.loading {
  animation: loader 0.5s infinite alternate-reverse;
  text-align: center;
}

.chatHeading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
}
.topics {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  text-align: center;
  margin: 20px 10px;
  /* align-items: center; */
  /* flex-grow: 1; */
}
.topics span {
  padding: 10px;
  /* margin: 10px; */
  border: 1px solid #1e1e1e;
  cursor: pointer;
  font-size: 18px;
}
.topics span:hover {
  background-color: peachpuff;
  color: black;
  transition: all 0.3s;
}
.highlighted-topic {
  background-color: #3ea7ec;
  color: white;
  transition: all 0.3s;
}
@keyframes loader {
  0% {
    opacity: 0;
    color: black;
  }

  100% {
    opacity: 1;
    color: #1ca7ec;
  }

  /* from {
    opacity: 0;
  }

  to
  {
    opacity: 1;
  } */
}
.noChats {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 30px;
  animation: noChats 1s infinite ease-in-out alternate;
}

@keyframes noChats {
  0% {
    color: #3ea7ec;
  }
  50% {
    color: #faf2e6;
  }
  100% {
    color: #000;
  }
}

@media screen and (max-width: 1024px) {
  .chatBox {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 0 auto !important;
  }
  .chat {
    max-width: 80%;
    margin-left: 0;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .chatBox {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .chat {
    margin-bottom: 20px;
    max-width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
  }
  .chatHeading {
    font-size: 20px;
  }
}

@media screen and (max-width: 420px) {
  .chatBox {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);
    padding: 5px;
  }
  .chat {
    max-height: 400px;
  }
  .left,
  .right {
    font-size: 12px;
  }
  .chat {
    margin-left: auto;
    margin-right: auto;
  }
  .loading {
    font-size: 1rem;
  }
}
