@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  /* margi/n: 0; */
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  font-family: "Lato", sans-serif;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
} */
.app {
  position: relative;
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100vh;
}

.sessionId {
  width: 80%;
  margin: auto;
}

.sessionId h2,
h5 {
  text-align: center;
}

.sessionWindow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: auto;

  padding: 15px 25px;
  margin: 20px;
  border-radius: 10px;
  color: white;
  /* animation: bG 1s infinite alternate-reverse; */
  background-color: rgba(62, 166, 236, 0.8);
}

.sessionWindow__heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 18px;
}
.sessionId {
  margin-bottom: 100px;
}
@-webkit-keyframes bG {
  0% {
    background-color: rgba(62, 166, 236, 0.8);
    border: 3px solid rgba(62, 167, 236, 0.8);
  }
  100% {
    background-color: rgba(62, 167, 236, 1);
    border: 3px solid rgba(62, 167, 236, 1);
  }
}
@keyframes bG {
  0% {
    background-color: rgba(62, 166, 236, 0.8);
    border: 3px solid rgba(62, 167, 236, 0.8);
  }
  100% {
    background-color: rgba(62, 167, 236, 1);
    border: 3px solid rgba(62, 167, 236, 1);
  }
}

@media screen and (max-width: 768px) {
  .sessionId {
    width: 85%;
  }
  .sessionWindow__heading {
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .sessionId {
    width: 95%;
  }
  .sessionWindow {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .sessionWindow__heading {
    font-size: 0.9rem;
  }
}

.chat {
  padding: 0px 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: auto;
  margin-right: 30px;
  max-width: 50%;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  overflow: scroll;
  height: 500px;
  max-height: 500px;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  margin-bottom: 50px;
}

.left {
  position: relative;
  border-radius: 10px;
  display: block;
  max-width: 400px;
  margin-right: auto;
  margin-bottom: 20px;
  background: linear-gradient(to right, #1ca7ec, #7bd5f5);
  color: white;
  font-size: 14px;
  text-align: left;
  padding: 10px 15px;
  /* box-shadow: 1px 1px 5px 1px lightgray; */
}

.dropShadow {
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.851));
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.851));
}

.left:hover {
  box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.left::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: -40px;
  border-radius: 50%;
  background: url(/static/media/Avatar-03.80adfd1f.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #ddd;
  /* box-shadow: 0px 0px 5px 1px rgba(28, 167, 236, 0.906); */
}

.right {
  position: relative;
  display: block;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px 15px;
  width: auto;
  max-width: 400px;
  margin-left: auto;
  margin-bottom: 20px;
  color: white;
  background: linear-gradient(90deg, #1ca7ec 0, #7ad5f5);
  box-shadow: 1px 1px 5px 1px lightgray;
  -webkit-appearance: initial;
}
.showTime {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: -16px;
  margin-bottom: 20px;
  margin-left: auto;
  color: lightslategray;
  font-size: small;
}

.right:hover {
  box-shadow: 1px 1px 5px 1px rgba(119, 115, 115, 0.707);
}

.right::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-right: -40px;
  border-radius: 50%;
  background: url("/static/media/CustomerIcon 1.72da8a00.svg");
  background-position: center center;
  background-size: contain;
  border: 1px solid #ddd;
  /* box-shadow: 0px 1px 10px rgba(123, 213, 245, 0.89); */
}

.chatBox {
  display: -webkit-flex;
  display: flex;
  width: auto;
  padding: 20px;
}

.loading {
  animation: loader 0.5s infinite alternate-reverse;
  text-align: center;
}

.chatHeading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
}
.topics {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-size: 12px;
  text-align: center;
  margin: 20px 10px;
  /* align-items: center; */
  /* flex-grow: 1; */
}
.topics span {
  padding: 10px;
  /* margin: 10px; */
  border: 1px solid #1e1e1e;
  cursor: pointer;
  font-size: 18px;
}
.topics span:hover {
  background-color: peachpuff;
  color: black;
  transition: all 0.3s;
}
.highlighted-topic {
  background-color: #3ea7ec;
  color: white;
  transition: all 0.3s;
}
@-webkit-keyframes loader {
  0% {
    opacity: 0;
    color: black;
  }

  100% {
    opacity: 1;
    color: #1ca7ec;
  }

  /* from {
    opacity: 0;
  }

  to
  {
    opacity: 1;
  } */
}
@keyframes loader {
  0% {
    opacity: 0;
    color: black;
  }

  100% {
    opacity: 1;
    color: #1ca7ec;
  }

  /* from {
    opacity: 0;
  }

  to
  {
    opacity: 1;
  } */
}
.noChats {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 30px;
  -webkit-animation: noChats 1s infinite ease-in-out alternate;
          animation: noChats 1s infinite ease-in-out alternate;
}

@-webkit-keyframes noChats {
  0% {
    color: #3ea7ec;
  }
  50% {
    color: #faf2e6;
  }
  100% {
    color: #000;
  }
}

@keyframes noChats {
  0% {
    color: #3ea7ec;
  }
  50% {
    color: #faf2e6;
  }
  100% {
    color: #000;
  }
}

@media screen and (max-width: 1024px) {
  .chatBox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 5px;
    margin: 0 auto !important;
  }
  .chat {
    max-width: 80%;
    margin-left: 0;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .chatBox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
  }
  .chat {
    margin-bottom: 20px;
    max-width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
  }
  .chatHeading {
    font-size: 20px;
  }
}

@media screen and (max-width: 420px) {
  .chatBox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: calc(100% - 2px);
    padding: 5px;
  }
  .chat {
    max-height: 400px;
  }
  .left,
  .right {
    font-size: 12px;
  }
  .chat {
    margin-left: auto;
    margin-right: auto;
  }
  .loading {
    font-size: 1rem;
  }
}

.chatTable {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* border: 1px solid lightgray; */
  box-shadow: 0px 0px 10px rgba(94, 90, 90, 0.8);
  border-radius: 20px;
  margin-left: 30px;
  height: 500px;
  max-height: 500px;
  max-width: 50%;
}

.chatTable__data {
  overflow: scroll;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-bottom: 30px;
}
.tableData td {
  text-transform: capitalize;
}

.tableData td {
  max-width: 100%;
}
/* .data {
	display: flex;
	flex-direction: row;
} */
/* 
.headers {
  font-weight: 800;
} */
/* .tableHeading {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  text-align: center;
} */
/* 
.tableRow:nth-child(2n + 1) {
  background-color: lightgray;
} */

/* .tableRow {
  height: 50px;
} */
.table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
}

/* .heading > h3 {
  display: flex;
  width: 33%;
  justify-content: center;
  text-align: center;
} */

/* .value {
  margin-right: 20px;
} */

/* th,
td {
  padding: 10px 3px;
} */

/* th,
td {
  border: 1px solid black;
  width: 30%;
} */

.tableData {
  width: 100%;
}

tbody {
  width: 100%;
}

.chattableheading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1ca7ec;
  border-radius: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 500px;
}
thead {
  border-bottom: 1px solid black;
}
table {
  border-collapse: collapse;
  overflow: hidden;
}

td {
  text-align: center !important;
}

/* tr {
	  border-bottom: 1pt solid black;
	} */
/* thead {
  margin-bottom: 10px;
  padding-bottom: 10px;
} */
.theadrow {
  /* margin-bottom: 10px; */
  color: #1e1e1e;
  font-size: 18px;
  padding: 5px 10px;
  border-bottom: 1px solid #4e4e4e;
  text-align: center;
}
.theadrow td {
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
}
.tableData td {
  padding: 15px 20px;
  /* text-align: center; */
}
table tr:nth-child(even) {
  background-color: rgb(248, 236, 220, 0.7);
}
.capitalise {
  text-transform: capitalize;
}

.user-data-table::-webkit-scrollbar {
  /* display: none; */
  width: 0;
}

.firstColumn,
.secondColumn,
.thirdColumn {
  width: 33% !important;
}

@media screen and (max-width: 1024px) {
  .chatTable {
    max-width: 80%;
    margin-left: 0;
    margin-top: 20px;
    margin: 20px auto;
  }
}

@media screen and (max-width: 768px) {
  .chatTable {
    max-width: calc(100% - 5vh);
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .table {
    width: auto;
    overflow-x: scroll;
    table-layout: auto;
    width: 100%;
  }
  .tableData h3 {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 12px;
  }
  th,
  td {
    width: 33%;
    max-width: 33%;
    height: 30px;
    font-size: 10px;
    table-layout: auto;
    /* width: 100%; */
  }
  .chattableheading {
    width: 350px;
    font-size: 20px;
  }

  .heading h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .chatTable {
    max-height: 400px;
  }
  th,
  td {
    padding: 5px 2px;
  }
  .heading h3 {
    font-size: 10px;
  }
  .chattableheading {
    width: 300px;
    font-size: 16px;
  }
  .firstColumn,
  .secondColumn,
  .thirdColumn {
    width: 30% !important;
  }
}

.ChatWindow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: auto;
  border: 2px solid black;
  padding: 15px 25px;
  margin: 20px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;

  /* margin-bottom: 100px; */
}
.ChatWindow:hover {
  background: linear-gradient(to right, #1ca7ec, #7bd5f5);
  border: solid #7bd5f5;
  box-shadow: 0px 4px 10px rgba(123, 213, 245, 0.913);
  color: white;
  /* transition: all 0.3s ease; */
  /* transition: background-color 0.5s ease; */
}

.ChatWindow:hover h4 {
  -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.851));
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.851));
}

.chatname .chatemail {
  display: block;
}

.userdetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.user {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.userID {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .ChatWindow {
    display: -webkit-flex;
    display: flex;
    /* flex-direction: row; */
  }
  .user {
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .ChatWindow {
    width: 100%;
    margin: 10px 0 0 0;
  }
  .user {
    font-size: 10px;
  }
}

.header {
  padding: 10px 50px;
  background-color: #1ca7ec;
  color: white;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
}
.header__logo {
  width: 200px;
  object-fit: contain;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.company__name {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 20px;
}

.header__midSectionSubHeading {
  text-align: center;
}

.header__logoSection {
  display: -webkit-flex;
  display: flex;
  max-width: 33%;
}

.header__logoImage {
  width: 100px;
  height: 80px;
  max-width: 105px;
}

.header__midSection {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 40%;
}
.header__midSection h2 {
  text-align: center;
}

.login__Button {
  background-color: #fff;
  color: #3ea7ec;
  outline: none;
  border-radius: 25px;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #3ea7ec;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-justify-content: center;
          justify-content: center;
  border: 2px solid #fff;
  padding: 1px;
  margin-left: 20px;
  display: block;
}

.logo::before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
}
/* 
h1 {
	margin-block-start: 0em;
	margin-block-end: 0em;
} */

.headerEmail {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
  /* color: #3ea7ec; */
}

.header__details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 33%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
}
.header__details div {
  width: auto;
}

.header__details a {
  color: #fff;
}

/* @media screen and (max-width: 1024px) {
	.company__name {
		margin-left: 0px;
	}
} */

.noLogo {
  display: none;
}

.logout__popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 30px;
  z-index: 9;
  position: absolute;
  top: 25%;
  left: 50%;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #3ea7ec;
  color: #000;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.logout_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(111, 197, 255, 0.3);
  z-index: 7;
}

.logout__popup h3 {
  text-align: center;
  margin: 20px auto;
}

.logout__popupButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.logout__confirm {
  background-color: #3ea7ec;
  color: #fff;
  border: 2px solid #3ea7ec;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  width: 120px;
  cursor: pointer;
  transition: 0.2s ease-in;
  margin: 10px;
}

.logout__confirm:hover {
  background-color: #fff;
  color: #3ea7ec;
}

.logout__cancel {
  background-color: red;
  color: #fff;
  border: 2px solid red;
  text-align: center;
  border-radius: 20px;
  width: 120px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: 0.2s ease-in;
}

.logout__cancel:hover {
  background-color: #fff;
  color: red;
}

@media screen and (max-width: 768px) {
  .header__logo {
    display: -webkit-flex;
    display: flex;
    font-size: 10px;
    width: auto;
  }
  .header__logoImage {
    max-width: 120px;
  }
  .header {
    padding: 20px;
  }
  .header__midSection {
    max-width: 50%;
  }
  .header__midSectionSubHeading {
    font-size: 12px;
    font-weight: 700;
  }
  .company__name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
    margin-left: 0px;
  }

  .header__logoSection {
    display: -webkit-flex;
    display: flex;
    max-width: 25%;
  }
  .header__details {
    max-width: 25%;
  }
  .headerEmail {
    font-size: 12px;
  }
}

@media screen and (max-width: 460px) {
  .header__logo {
    margin-left: auto;
    margin-right: 40px;
    margin-bottom: 20px;
    font-size: 1rem;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .header__logoImage {
    margin: auto;
  }
  .headerEmail {
    text-align: left;
  }
  .header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 20px;
  }
  .header__midSection,
  .header__logoSection,
  .header__details {
    max-width: 100%;
  }
  .header__logoSection {
    width: auto;
  }
  .company__name {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .login p {
    margin-top: 70px;
  }
}

.login {
  /* position: absolute; */

  max-width: 300px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.login p {
  text-align: center;
  font-size: 30px;
  color: #3ea7ec;
  margin-bottom: 30px;
  font-weight: 800;
}

.login form {
  display: -webkit-flex;
  display: flex;
  height: 280px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 2px solid lightgray;
  padding: 30px;
  border-radius: 20px;
}

.login input {
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.login__button {
  margin-top: 20px;
  background-color: #3ea7ec;
  color: white;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #3ea7ec;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  font-size: 18px;
}

.login__button:hover {
  background-color: #fff;
  color: #3ea7ec;
  border: 2px solid #3ea7ec;
  box-sizing: border-box;
}

.error {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  padding: 10px 5px;
  border-radius: 20px;
  border: 2px solid #571515;
  font-size: 14px;
  -webkit-animation: error 1s infinite alternate;
          animation: error 1s infinite alternate;
}

@-webkit-keyframes error {
  from {
    background-color: #571515;
  }
  to {
    background-color: #ba2a2a;
    /* color: #ed1f30; */
  }
}

@keyframes error {
  from {
    background-color: #571515;
  }
  to {
    background-color: #ba2a2a;
    /* color: #ed1f30; */
  }
}

.footer-top-section,
.footer-mid-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  width: 100%;
  font-family: "Lato";
}
.footer-mid-section {
  width: 65%;
  margin: 20px auto;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  border-width: 0px 0px 0px 0px;
  border-color: #ececed;
  border-style: solid;
}
.footer-top-section > div {
  width: 33%;
}
.footer-top-section > div:nth-child(2) {
  width: 30%;
}
.top-second,
.footer-bottom-section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
.footer-bottom-section {
  border-top: 1px solid #eee;
  padding-top: 40px;
  width: 90%;
  margin: 20px auto;
  line-height: 1.5;
  color: #919191;
  text-align: center;
}
.footer-bottom-section p {
  margin: 0;
}

.footer-bottom-section p,
.footer-top-section p {
  text-align: center;
}
.location-title {
  font-size: 16px;
  line-height: 1.2em !important;
  text-align: center;
  text-transform: uppercase;
  color: #3f3f3f;
}
.location-title {
  /* color: red; */
  font-size: 16px;
}
.location-address {
  font-size: 15px;
  /* line-height: 0.6em !important; */
  text-align: center;
  color: #919191;
  font-size: 15px;
  line-height: 0.6em !important;
  text-align: center;
  color: #919191;
}
.location-address p {
  font-size: 15px;
}
.top-second img {
  width: inherit;
}
.top-second-social-media {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 5px;
}
.footer-mid-firstimage {
  width: 15%;
}
.footer-mid-secondimage {
  width: 10%;
  height: auto;
}
.footer-mid-thirdimage {
  width: 55%;
}
@media screen and (min-width: 300px) and (max-width: 680px) {
  .section > div {
    width: 100%;
  }

  .footer-mid-section {
    width: 100%;
  }
  .footer-mid-firstimage {
    width: 70%;
    margin: 10px auto;
  }
  .footer-mid-secondimage {
    width: 35%;
    margin: 10px auto;
  }
  .footer-mid-thirdimage {
    width: 85%;
    margin: 10px auto;
  }
  .footer-top-section,
  .footer-mid-section,
  .footer-bottom-section {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    /* line-height: 0.5em; */
    font-size: 10px;
  }
}

.change-email-params {
  width: 60%;
  margin: 100px auto;
  padding: 2rem;
  text-align: center;
}
.change-email-params input {
  padding: 20px 10px;
  margin: 10px 0px;
  background: #fff;
  outline: none;
}
.change-email-params button {
  width: 50%;
  text-align: center;
  padding: 20px 10px;
  border: 1px solid #1ca7ec;
  border-radius: 5px;
  outline: none;
  background: #1ca7ec;
  color: #fff;
  cursor: pointer;
}
button:active {
  outline: none;
}

