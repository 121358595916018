.header {
  padding: 10px 50px;
  background-color: #1ca7ec;
  color: white;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.header__logo {
  width: 200px;
  object-fit: contain;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.company__name {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.header__midSectionSubHeading {
  text-align: center;
}

.header__logoSection {
  display: flex;
  max-width: 33%;
}

.header__logoImage {
  width: 100px;
  height: 80px;
  max-width: 105px;
}

.header__midSection {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.header__midSection h2 {
  text-align: center;
}

.login__Button {
  background-color: #fff;
  color: #3ea7ec;
  outline: none;
  border-radius: 25px;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #3ea7ec;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  border: 2px solid #fff;
  padding: 1px;
  margin-left: 20px;
  display: block;
}

.logo::before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
}
/* 
h1 {
	margin-block-start: 0em;
	margin-block-end: 0em;
} */

.headerEmail {
  justify-content: flex-end;
  text-align: right;
  /* color: #3ea7ec; */
}

.header__details {
  display: flex;
  align-items: center;
  max-width: 33%;
  justify-content: center;
  padding: 10px;
}
.header__details div {
  width: auto;
}

.header__details a {
  color: #fff;
}

/* @media screen and (max-width: 1024px) {
	.company__name {
		margin-left: 0px;
	}
} */

.noLogo {
  display: none;
}

.logout__popup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: fit-content;
  height: fit-content;
  padding: 30px;
  z-index: 9;
  position: absolute;
  top: 25%;
  left: 50%;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #3ea7ec;
  color: #000;
  transform: translate(-50%, -50%);
}

.logout_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(111, 197, 255, 0.3);
  z-index: 7;
}

.logout__popup h3 {
  text-align: center;
  margin: 20px auto;
}

.logout__popupButtons {
  display: flex;
  justify-content: space-around;
}

.logout__confirm {
  background-color: #3ea7ec;
  color: #fff;
  border: 2px solid #3ea7ec;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  width: 120px;
  cursor: pointer;
  transition: 0.2s ease-in;
  margin: 10px;
}

.logout__confirm:hover {
  background-color: #fff;
  color: #3ea7ec;
}

.logout__cancel {
  background-color: red;
  color: #fff;
  border: 2px solid red;
  text-align: center;
  border-radius: 20px;
  width: 120px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: 0.2s ease-in;
}

.logout__cancel:hover {
  background-color: #fff;
  color: red;
}

@media screen and (max-width: 768px) {
  .header__logo {
    display: flex;
    font-size: 10px;
    width: auto;
  }
  .header__logoImage {
    max-width: 120px;
  }
  .header {
    padding: 20px;
  }
  .header__midSection {
    max-width: 50%;
  }
  .header__midSectionSubHeading {
    font-size: 12px;
    font-weight: 700;
  }
  .company__name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 0px;
  }

  .header__logoSection {
    display: flex;
    max-width: 25%;
  }
  .header__details {
    max-width: 25%;
  }
  .headerEmail {
    font-size: 12px;
  }
}

@media screen and (max-width: 460px) {
  .header__logo {
    margin-left: auto;
    margin-right: 40px;
    margin-bottom: 20px;
    font-size: 1rem;
    justify-content: center;
  }
  .header__logoImage {
    margin: auto;
  }
  .headerEmail {
    text-align: left;
  }
  .header {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .header__midSection,
  .header__logoSection,
  .header__details {
    max-width: 100%;
  }
  .header__logoSection {
    width: auto;
  }
  .company__name {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .login p {
    margin-top: 70px;
  }
}
